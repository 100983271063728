import { useState } from "react";

function ContactForm() {
  const [success, setSuccess] = useState(false);
  const [buttonText, setButtonText] = useState("Send message");
  const [buttonDisabled, setButtonDisabled] = useState(false);

  const [Data, setData] = useState({
    name: "",
    country: "",
    email: "",
    contact_no: "",
    message: "",
  });

  const submitForm = async (e) => {
    e.preventDefault();
    debugger;
    requestServer();
  };

  const requestServer = async () => {
    try {
      setButtonText("Sending, please wait...");
      setButtonDisabled(true);
      const formData = new FormData();
      formData.append("name", Data.name);
      formData.append("country", Data.country);
      formData.append("email", Data.email);
      formData.append("contact_no", Data.contact_no);
      formData.append("message", Data.message);
      const response = await fetch(
        "https://www.ritmudraw24.com/Links/add_messageWeb",
        {
          method: "POST",
          mode: "cors",
          body: formData,
        }
      );
      if (response.status === 200) {
        setSuccess(true);
        setTimeout(() => {
          setSuccess(false);
        }, 5000);
      } else {
        console.error("Error:", response.statusText);
        return { status: "failed", data: response.statusText };
      }
      setButtonText("Send message");
      setButtonDisabled(false);
    } catch (error) {
      console.error("Error:", error);
      return { status: "failed", data: error.message };
    }
  };
  return (
    <section className="contact-section" id="contact">
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-3"></div>
          <div className="col-lg-6 p-0">
            <div className="contact-warp">
              <div className="section-title mb-0">
                <h2>Get in touch</h2>
              </div>
              <p>
                We are to help and answer any questions you might have. We look
                forward to hearing from you
              </p>
              <ul style={{ marginBottom: "0px" }}>
                <li>133 st Al farwaniyah, Kuwait</li>
                <li>
                  <a href="mailto:info@ritmu.tv">info@ritmu.tv</a>
                </li>
              </ul>
              <div className="contact-from">
                <form id="Quote_message_form" onSubmit={submitForm}>
                  <p
                    className="msg text-center text-danger"
                    style={{ paddingTop: 0, marginBottom: 0 }}
                  ></p>
                  <div className="row mt-1">
                    <div className="col-md-6">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Your name"
                        onChange={(e) => {
                          setData((prevFormData) => ({
                            ...prevFormData,
                            name: e.target.value,
                          }));
                        }}
                        name="name"
                        required
                      />
                    </div>
                    <div className="col-md-6">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Country"
                        onChange={(e) => {
                          setData((prevFormData) => ({
                            ...prevFormData,
                            country: e.target.value,
                          }));
                        }}
                        name="country"
                        required
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-6">
                      <input
                        type="email"
                        className="form-control"
                        placeholder="E-mail"
                        onChange={(e) => {
                          setData((prevFormData) => ({
                            ...prevFormData,
                            email: e.target.value,
                          }));
                        }}
                        name="email"
                        required
                      />
                    </div>
                    <div className="col-md-6">
                      <input
                        type="number"
                        className="form-control"
                        placeholder="Phone"
                        onChange={(e) => {
                          setData((prevFormData) => ({
                            ...prevFormData,
                            contact_no: e.target.value,
                          }));
                        }}
                        required
                        name="contact_no"
                      />
                    </div>
                  </div>
                  <textarea
                    placeholder="Message"
                    className="form-control"
                    onChange={(e) => {
                      setData((prevFormData) => ({
                        ...prevFormData,
                        message: e.target.value,
                      }));
                    }}
                    name="message"
                    required
                  ></textarea>
                  {success ? (
                    <div
                      className="alert alert-success text-center"
                      role="alert"
                    >
                      <strong>Success</strong> Message is successfully send.!
                      Team will contact you shortly...!
                    </div>
                  ) : (
                    ""
                  )}
                  <button
                    className="site-btn btn-block"
                    type="submit"
                    id="emailButton"
                    disabled={buttonDisabled}
                  >
                    {buttonDisabled ? (
                      <span className="fa fa-spinner fa-spin"></span>
                    ) : (
                      ""
                    )}{" "}
                    {buttonText}
                  </button>
                </form>
              </div>
            </div>
          </div>
          <div className="col-md-3"></div>
        </div>
      </div>
    </section>
  );
}

export default ContactForm;
