import hero_bg from "../Styles/hero-bg.webp"; //" ../Styles/hero-bg.webp";
import backgroundImage from "../Styles/how-to-bg.jpg"; //" ../Styles/hero-bg.webp";

function SubHeader() {
  return (
    <section
      className="hero-section"
      style={{
        backgroundImage: `url(${backgroundImage})`,
        padding: "100px 0",
        color: "white",
      }}
    >
      <div className="container">
        <div className="row">
          <div className="col-12 col-xs-12 col-sm-12 col-md-6 col-lg-6">
            <img src={hero_bg} alt="" />
          </div>
          <div className="col-12 col-xs-12 col-sm-12 col-md-6 col-lg-6">
            <div className="hs-text">
              <h2>
                <span>Live TV</span> for everyone.
              </h2>
              <p>
                Ritmu TV gives you access to watch thousands of our latest On
                Demand shows and movies for free.
              </p>
              <a
                href="Ritmu-Al-Kuwait.apk"
                download
                className="btn btn-danger"
                style={{ marginBottom: "10px" }}
              >
                <i className="fa fa-download"></i> Download APK
              </a>
              &nbsp;&nbsp;
              {/* <a
                target="_blank"
                href="https://play.google.com/store/apps/details?id=com.apps.ritmutvnew&hl=en&gl=US"
                className="btn btn-danger"
                style={{ marginBottom: "10px" }}
              >
                <i className="fa fa-play"></i> Download from Play Store
              </a> */}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default SubHeader;
