import { useState } from "react";
import logo from "../Styles/ritmu-logo.png";

function Header() {
  const [isMenuVisible, setMenuVisible] = useState(false);


  return (
    <>
      <nav style={{ background: "#08192d" }}>
        <input
          type="checkbox"
          checked={isMenuVisible}
          readOnly
          id="nav-toggle"
        />
        <div className="logo">
          <a href="#" className="site-logo">
            <img style={{ maxHeight: "100px" }} src={logo} />
          </a>
        </div>
        <ul className="links" htmlFor="nav-toggle">
          <li>
            <a
              href=""
              onClick={() => {
                setMenuVisible(false);
              }}
            >
              <i className="fa fa-home"></i> Home
            </a>
          </li>
          <li>
            <a
              href="#livetv"
              onClick={() => {
                setMenuVisible(false);
              }}
            >
              <i className="fa fa-rss"></i> Live Tv
            </a>
          </li>
          <li>
            <a
              href="#draw"
              onClick={() => {
                setMenuVisible(false);
              }}
            >
              <i className="fa fa-gift"></i> Draw
            </a>
          </li>
          <li>
            <a
              href="#contact"
              onClick={() => {
                setMenuVisible(false);
              }}
            >
              <i className="fa fa-address-book"></i> Contact
            </a>
          </li>
          <li>
            <a
              onClick={() => {
                setMenuVisible(false);
              }}
              href="Ritmu-Al-Kuwait.apk"
              className="btn btn-info btn-sm"
              download
              style={{ fontSize: "12px" }}
            >
              <i className="fa fa-download"></i> Download App
            </a>
          </li>
        </ul>
        <label
          htmlFor="nav-toggle"
          className="icon-burger"
          onClick={() => {
            setMenuVisible(!isMenuVisible);
          }}
        >
          <div className="line"></div>
          <div className="line"></div>
          <div className="line"></div>
        </label>
      </nav>
    </>
  );
}

export default Header;
