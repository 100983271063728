import Icon from "../Styles/Icon.png";

import { useState } from "react";

function Draws() {
  const [Data, setData] = useState({
    start_date: "",
    end_date: "",
  });
  const [draws, setDraws] = useState([]);
  const [showDraws, setShowDraws] = useState(false);
  const [buttonText, setButtonText] = useState("Find draws");
  const [buttonDisabled, setButtonDisabled] = useState(false);

  const submitForm = async (e) => {
    e.preventDefault();
    debugger;
    requestServer(Data.start_date, Data.end_date);
  };

  const requestServer = async (start_date, end_date) => {
    try {
      setButtonText("Fetching, please wait....");
      setButtonDisabled(true);

      const formData = new FormData();
      formData.append("start_date", start_date);
      formData.append("end_date", end_date);
      const response = await fetch(
        "https://www.ritmudraw24.com/Links/draws_forWeb",
        {
          method: "POST",
          mode: "cors",
          body: formData,
        }
      );
      if (response.status === 200) {
        const data = await response.json();
        setShowDraws(true);
        setDraws(data);
      } else {
        // Handle non-200 status codes
        setDraws([]);
        console.error("Error:", response.statusText);
        return { status: "failed", data: response.statusText };
      }
      setButtonText("Find draws");
      setButtonDisabled(false);
    } catch (error) {
      setDraws([]);
      console.error("Error:", error);
      return { status: "failed", data: error.message };
    }
  };
  return (
    <section
      id="draw"
      style={{ width: "100%" }}
      className="subscription-section spad2"
    >
      <div className="container ">
        <div className="sub-text text-center">
          <h2 style={{ fontSize: "55px" }}>
            <img
              style={{ position: "relative" }}
              src={Icon}
              width="100px"
              alt=""
            />
            <br />
            DRAW RESULT
          </h2>
        </div>

        <form id="get_draws" onSubmit={submitForm}>
          <div className="row" style={{ color: "white" }}>
            <div className="col-12 col-xs-12 col-sm-12 col-md-12 col-lg-6">
              <label>Start Date</label>
              <input
                type="date"
                name="start_date"
                onChange={(e) => {
                  setData((prevFormData) => ({
                    ...prevFormData,
                    start_date: e.target.value,
                  }));
                }}
                max={new Date().toISOString().split("T")[0]}
                required
                className="form-control"
              />
            </div>
            <div className="col-12 col-xs-12 col-sm-12 col-md-12 col-lg-6">
              <label>End Date</label>
              <div>
                <input
                  type="date"
                  name="end_date"
                  onChange={(e) => {
                    setData((prevFormData) => ({
                      ...prevFormData,
                      end_date: e.target.value,
                    }));
                  }}
                  max={new Date().toISOString().split("T")[0]}
                  required
                  className="form-control"
                />
              </div>
            </div>
          </div>
          <div className="col-lg-12 text-center" style={{ marginTop: "20px" }}>
            <button
              id="search_button"
              type="submit"
              disabled={buttonDisabled}
              className="lucky-btn mx-auto btn-block"
            >
              {buttonDisabled ? (
                <span className="fa fa-spinner fa-spin"></span>
              ) : (
                ""
              )}{" "}
              {buttonText}
            </button>
          </div>
        </form>
        <div
          className="row"
          id="draw_records"
          style={{ display: showDraws ? "block" : "none" }}
        >
          <div className="col-lg-12">
            <br />
            <table
              id="record_table"
              style={{ color: "white" }}
              className="table table-bordered table-striped table-sm"
            >
              <thead>
                <tr style={{ background: "#f5c6cb" }}>
                  <th style={{ width: "30%" }} className="text-center">
                    <h4>Time</h4>
                  </th>
                  <th style={{ width: "70%" }} className="text-center">
                    <h4>Draw</h4>
                  </th>
                </tr>
              </thead>
              <tbody>
                {draws.map((draw, index) => (
                  <tr key={index}>
                    <td className="text-center">
                      {draw.full_date} - {draw.time}
                    </td>
                    <td className="text-center">{draw.draw}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Draws;
