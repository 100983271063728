import "./Styles/App.css";
import "./Styles/slicknav.min.css";
import "./Styles/owl.carousel.min.css";
import "./Styles/bootstrap/bootstrap.css";
import "./Styles/fontAwesome/css/all.css";
import Draws from "./Components/Draws";
import SubHeader from "./Components/SubHeader";
import Header from "./Components/Header";
import MusicPlayer from "./Components/MusicPlayer";
import ContactForm from "./Components/ContactForm";

function App() {
  return (
   <div >
		Allah hu
      <Header />
      <SubHeader />
      <MusicPlayer />
      <Draws />
      <ContactForm />
      </div>
  );
}

export default App;
