import ReactHlsPlayer from "react-hls-player";

function MusicPlayer() {
  return (
    <section id="livetv" className="intro-section spad">
      <div className="container">
        <div
          className="text-center row"
          style={{ display: "block !important" }}
        >
          <div className="col-12 col-xs-12 col-sm-12 col-md-12 col-lg-12">
            <div className="sub-text text-center">
              <h2 style={{ fontSize: "55px", color: "#0a183d" }}>
                <span className="fa fa-rss"></span> LIVE TV
              </h2>
            </div>
          </div>
          <div className="col-12 col-xs-12 col-sm-12 col-md-12 col-lg-12">
            <div className="mt-2 mb-2">
              <marquee
                width="60%"
                scrolldelay="90"
                className="mt-3 mb-3"
                direction="left"
              >
                <h4 style={{ color: "#0a183d" }}>www.ritmu.tv</h4>
              </marquee>
            </div>
          </div>
          <div className="col-2 col-xs-2 col-sm-2 col-md-2 col-lg-2"></div>
          <div className="col-12 col-xs-12 col-sm-12 col-md-12 col-lg-12">
            <ReactHlsPlayer
              src="https://659605a54e9a6.streamlock.net:443/live/livestream/playlist.m3u8"
              autoPlay={true}
              controls={true}
              width="100%"
              height="auto"
            />
          </div>
          <div className="col-2 col-xs-2 col-sm-2 col-md-2 col-lg-2"></div>
          <div className="col-12 col-xs-12 col-sm-12 col-md-12 col-lg-12">
            <div className="mt-2 mb-2">
              <marquee
                width="60%"
                scrolldelay="150"
                className="mt-3 mb-3"
                direction="left"
              >
                <h4 style={{ color: "#0a183d" }}>www.ritmu.tv</h4>
              </marquee>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default MusicPlayer;
